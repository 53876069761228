import type { NavLinkProps } from '@remix-run/react'
import { NavLink, useNavigation } from '@remix-run/react'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { cn } from '~/styles/utils'
import { buttonVariants } from '../ui/button'
import { Icon } from '../ui/icon'
import { ScrollArea } from '../ui/scroll-area'
import { sidebarOpenAtom } from './layout'

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> { }

export function Sidebar({ className }: SidebarProps) {
  const navigation = useNavigation()
  const setOpenSidebar = useSetAtom(sidebarOpenAtom)

  const navlinkClassName: NavLinkProps['className'] = ({
    isActive,
    isPending,
    isTransitioning,
  }) => {
    return cn(
      buttonVariants({
        variant: isActive ? 'secondary' : 'ghost',
        size: 'default',
        className: '',
      }),
      'w-full justify-start rounded-none border-b border-gray-200',
      isActive
        ? 'bg-sky-200 hover:bg-sky-600 hover:text-sky-100 text-sky-900 !outline-none border-none'
        : '',
      '[&[data-disabled]]:opacity-40',
    )
  }

  useEffect(() => {
    if (navigation.state != 'idle') {
      setOpenSidebar(false)
    }
  }, [navigation.state, setOpenSidebar])

  return (
    <ScrollArea className={cn('pb-4', className)}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>Finans</strong>
              <NavLink to="/" className={navlinkClassName}>
                <Icon name="hugeicons:dashboard-square-add" className="mr-2 size-4" />
                Dashboard
              </NavLink>
            </div>
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>Finans</strong>
              <NavLink to="/tr/kasa" className={navlinkClassName}>
                <Icon name="fa6-solid:turkish-lira-sign" className="mr-2 size-4" />
                Kasa
              </NavLink>
              <NavLink to="/tr/veresiye" className={navlinkClassName}>
                <Icon name="fluent:wallet-credit-card-32-regular" className="mr-2 size-4" />
                Veresiye
              </NavLink>

              <NavLink to="/tr/kasa-rapor" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Kasa Raporu
              </NavLink>
            </div>
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>Satış</strong>
              <NavLink to="/tr/domain" className={navlinkClassName}>
                <Icon name="material-symbols:cloud-domains" className="mr-2 size-4" />
                Domain
              </NavLink>
              <NavLink to="/tr/hosting" className={navlinkClassName}>
                <Icon name="carbon:ibm-cloud-direct-link-1-dedicated-hosting" className="mr-2 size-4" />
                Hosting
              </NavLink>
              <NavLink to="/tr/ssl" className={navlinkClassName}>
                <Icon name="carbon:ibm-cloud-direct-link-1-dedicated-hosting" className="mr-2 size-4" />
                SSL
              </NavLink>
              <NavLink to="/tr/sunucu" className={navlinkClassName}>
                <Icon name="hugeicons:cloud-server" className="mr-2 size-4" />
                Sunucu
              </NavLink>
              <NavLink to="/tr/satis-rapor" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Satış Raporu
              </NavLink>
            </div>
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>Projeler</strong>
              <NavLink to="/tr/teklif" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Teklifler
              </NavLink>
              <NavLink to="/tr/projeler" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Projeler
              </NavLink>
              <NavLink to="/tr/proje-arsiv" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Proje Arşivi
              </NavLink>
              <NavLink to="/tr/proje-iptal" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                İptal
              </NavLink>
            </div>
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>CRM</strong>
              <NavLink to='/tr/ajanda' className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Ajanda
              </NavLink>
              <NavLink to='/tr/gorumseller' className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Görüşmeler
              </NavLink>
              <NavLink to="/tr/fatura" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Faturalar
              </NavLink>
            </div>
            <div className='mb-4 bg-gray-50'>
              <strong className={styles.strong}>Ayarlar</strong>
              <NavLink to="/tr/musteri" className={navlinkClassName}>
                <Icon name="ph:users-four-light" className="mr-2 size-4" />
                Müşteriler/Firmalar
              </NavLink>
              <NavLink to="/tr/kategori" className={navlinkClassName}>
                <Icon name="iconoir:reports" className="mr-2 size-4" />
                Kategori
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </ScrollArea >
  )
}


const styles = {
  strong: 'border-b border-solid border-gray-200 w-full flex justify-center bg-gray-100',
}
